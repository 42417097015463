import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { withStyles, Theme } from '@material-ui/core/styles'
// import { Button, Flex, Text } from 'rebass'
// import styled from '@emotion/styled'
import { Link } from 'gatsby'

import SEO from '../components/seo'
import { Layout } from '../components/layout'

// const HomeButton = styled(Button)`
//   font-family: 'Avenir';
//   border-color: #3c8aef;
//   background-color: #3c8aef;
//   border-width: 1px;
//   padding: 10px;
//   align-items: center;
//   justify-content: center;
//   border-radius: 5px;
//   box-shadow: 0px 3px 20px rgba(60, 137, 239, 0.5);
//   flex-basis: auto;
//   min-width: 245;
//   max-width: 100%;
//   height: 65px;
//   padding-left: 40px;
//   padding-right: 40px;
//   margin-top: 30px;
//   cursor: pointer;
//   color: #fff;
// `

const BlueButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText('#007bff'),
    // boxShadow: 'none',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'rgba(60, 137, 239)',
    borderColor: 'rgba(60, 137, 239)',
    boxShadow: '0px 3px 20px rgba(60, 137, 239, 0.5)',
    fontFamily: ['Nunito'].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf'
    },
    '&:focus': {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  }
}))(Button)

export default () => (
  <Layout>
    <SEO title="404: Not Found" />
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        paddingY={4}
        alignItems="center"
      >
        <Typography variant="h1" align="center" gutterBottom>
          404
        </Typography>
        <Typography variant="h3" align="center" gutterBottom>
          Ooooops!
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          THAT PAGE DOESN'T EXIST OR IS UNAVAILABLE
        </Typography>
        <BlueButton>Go Back to Home</BlueButton>
      </Box>
    </Container>
  </Layout>
)
